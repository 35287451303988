div.loginContentControl {
    display: flex;
    flex-direction:row;
    align-items: flex-start
}

div.loginContentControl > div.rememberMe {
    width: 12.5vw;
}

div.loginContentControl > div.forgetPassword {
    display: flex;
    flex-direction:row;
    align-items: flex-end;
    justify-content: right;
    margin: 1vb 1vb;
    width: 12.5vw;
}