div.designBackground {
  background: url('/public/img/design_background.png') no-repeat;
  background-position-x: -35px;
  background-position-y: -35px;
}

div.design {
  position: absolute;
  top: 8vh;
  width: 100vw;
  height: 92vh;
}

div.design > iframe {
  width: 99vw;
  height: 91vh;
}