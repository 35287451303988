div.loginFormScene{
    position: absolute;
    top: -4vh;
    left: -1vw;
    width: 32vw;
    height: 55vh;
    background-color: transparent;
    z-index: 4;
}

div.loginFormScene_human1{
    position: absolute;
    top: 20vh;
    left: 8vw;
    width: 12.5vw;
    height: 28vh;
    background-color: transparent;
    z-index: 5;
}

div.loginFormScene_human2{
    position: absolute;
    top: 11.5vh;
    left: 19.5vw;
    width: 10.5vw;
    height: 36vh;
    background-color: transparent;
    z-index: 5;
}

div.loginFormContent{
    position:absolute;
    top: 0vh;
    left: 32vw;
    width: 28vw;
    height: 57vh;
    z-index: 4;
    opacity: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}