div.about {
  position: absolute;
  top: 0vh;
  left: 0vw;
  width: 100vw; 
  height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
}

div.about > div.content {
    position: absolute;
    top: 0vh;
    left: -20vw;
    width: 140vw; 
    height: 400vh;
}