div.formBackground {
  background: url('/public/img/login_background.png') no-repeat;
  background-position-x: -35px;
  background-position-y: -35px;
}
  
div.form {
  position: absolute;
  top: 8vh;
  width: 100vw;
  height: 92vh;
}
  
div.form > div.title{
  position: absolute;
  top: 2vh; 
  left: 25vw;
  width: 50vw;
  height: 15vh;
  color: white;
  font: 700 12vh "Segoe UI", sans-serif;
  text-shadow: #00000080 5px 3px 8px;
}
  
div.form > div.ball1{
  position: absolute;
  top: 45vh; 
  left: 4vw;
  width: 45vb;
  height: 45vb;
  overflow: hidden;
  z-index: 2;
}
  
div.form > div.ball1 > div.content{
  position: absolute;
  top: -10vb; 
  left: -10vb;
  width: 65vb;
  height: 65vb;
  background-color: transparent;
  opacity: 0.82;
}
  
div.form > div.ball2{
  position: absolute;
  top: 28vh; 
  left: 15vw;
  width: 40vb;
  height: 40vb;
  overflow: hidden;
  z-index: 1;
}
  
div.form > div.ball2 > div.content{
  position: absolute;
  width: 40vb;
  height: 40vb;
  background-color: transparent;
  filter: blur(2vb);
}
  
div.form > div.ball3{
  position: absolute;
  top: 0vh; 
  left: 55vw;
  width: 80vb;
  height: 80vb;
  overflow: hidden;
  z-index: 2;
}
  
div.form > div.ball3 > div.content{
  position: absolute;
  top: -7vh; 
  left: 0vw;
  width: 90vb;
  height: 90vb;
  background-color: transparent;
  opacity: 0.9;
}
  
div.form > div.ball4{
  position: absolute;
  top: 62vh; 
  left: 60vw;
  width: 25vb;
  height: 25vb;
  overflow: hidden;
  z-index: 1;
}
  
div.form > div.ball4 > div.content{
  position: absolute;
  top: 0; 
  left: 0;
  width: 25vb;
  height: 25vb;
  background-color: transparent;
  filter: blur(2vb);
}

div.form > div.dialog{
  position: absolute;
  top: 21vh;
  left: 15vw;
  width: 65vw;
  height: 65vh;
  background-color: white;
  border: white 0.05vb;
  border-radius: 10vb;
  opacity: 0.5;
  box-shadow: #00000087 10px 3px 30px;
  z-index: 3;
  color: #434343;
  font: 400 2vb "Segoe UI", sans-serif;
}

div.form > div.baseContent{
    position:absolute;
    top: 25vh;
    left: 16vw;
    width: 62.75vw;
    height: 57.25vh;
    z-index: 4;
    opacity: 1;
    display: block;
    overflow: auto;
}
    
div.row {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 1vb 1vb;
  width: 27vw;
}
  
div.checkbox {
  display: flex;
  flex-direction:row-reverse;
  align-items: flex-start;
  justify-content: left;
  margin: 1vb 1vb;
}
    
label {
  font-weight: 700;
  padding: 0.25vb;
}
  
label.error {
  color: red;
  padding: 0.25vb; 
  font-weight: 400;
}
  
input {
  height: 5vb;
  width: 25vw;
  color: #2b2b2b;
  border-radius: 1vb;
  border: 0.15vb solid #707070;
  padding-left: 1vb;
  background-color: transparent;
}
  
input[type=button] {
  height: 5vb;
  width: 25.5vw;
  color: #2b2b2b;
  font: 700 2vb "Segoe UI", sans-serif;
  border-radius: 1vb;
  border: 0.15vb solid white;
  padding: 1vb;
  background-color: white;
}
  
input[type=button]:hover {
  font-weight: 900;
}

input[type=checkbox] {
  height: 2vb;
  width: 2vb;
  color: #2b2b2b;
  border: 0.15vb solid #707070;
  margin-top: 0.6vb;
}