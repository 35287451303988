div.menu {
    position: fixed;
    color: black;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 8vh;
    background: #f2f2f2;
    box-shadow: 0px 5px 10px #00000029;
    border-radius: 0px 0px 5vb 5vb;
    opacity: 1;
    z-index: 2;
}

div.menuLogo {
    float: left;
    width: 12vw;
    height: 7vh;
    position: relative;
    top: 0.4vh;
    left: 1.8vw;
    display: block;
    opacity: 1;
}
  
div.menuLink {
    float: left;
    clear: none; 
    position:relative;
    left:2.5vw;
    top: 2.8vh;
}
  
ul.menu {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
}
  
ul.menu > li {
    float: left;
}
  
ul.menu > li > a {
    display: block;
    color: #707070;
    text-align: center;
    padding: 1.4vb 1.6vb;
    text-decoration: none;
    font: 500 2vb "Segoe UI", sans-serif;
}
  
ul.menu > li > a.active {
    display: block;
    color: #2b2b2b;
    text-align: center;
    padding: 1.4vb 1.6vb;
    text-decoration: none;
    font: 700 2vb "Segoe UI", sans-serif;
}
