div.loginMenu {
  float: right;
  clear: none; 
  position: relative;
  right: 3vw;
  top: 1.5vh;
}

div.loginMenu > div {
  float: right;
}
  
div.loginMenu > div > input.registerButton[type='button'] {
  border: solid 0.3vb #f2f2f2;
  background: linear-gradient(135deg, #eb98c0 0%, #49bde8 61.5%, #07a8a9 100%);
  color: white;
  padding: 0.25vb 1vb;
  margin: 0.5vb;
  font: 400 2vb "Segoe UI", sans-serif;
  border-radius: 2vb;
  width: 7.5vw;
}
  
div.loginMenu > div > input.loginButton[type='button'] {
  border: solid 0.3vb transparent;
  border-radius: 2vb;
  background: 
    linear-gradient(to right, #f2f2f2, #f2f2f2 ),
    linear-gradient(135deg, #eb98c0 0%, #49bde8 61.5%, #07a8a9 100%);
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  color: #707070;
  padding: 0.2vb 1vb;
  margin: 0.8vb;
  font: 400 2vb "Segoe UI", sans-serif;
  width: 7.5vw;
  cursor: pointer;
}

div.loginMenu > div > input.menuButton[type='button'] {
  border: solid 0.3vb transparent;
  border-radius: 2vb;
  background: 
    linear-gradient(to right, #f2f2f2, #f2f2f2 ),
    linear-gradient(135deg, #eb98c0 0%, #49bde8 61.5%, #07a8a9 100%);
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  color: #707070;
  padding: 0.2vb 1vb;
  margin: 0.8vb;
  font: 400 2vb "Segoe UI", sans-serif;
  width: 15vw;
  cursor: pointer;
}

div.loginMenu > div > p {
  color: #2b2b2b;
  font: 500 2vb "Segoe UI", sans-serif;
  padding: 0.2vb 1vb;
  margin: 0.8vb;
  flex-flow: column;
}

div.loginMenu > div.user {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

div.loginMenu > div.user > div.userMenu {
  background: 
    linear-gradient(to right, #f2f2f2, #f2f2f2 ),
    linear-gradient(135deg, #eb98c0 0%, #49bde8 61.5%, #07a8a9 100%);
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  width: 15vw;
  position: relative;
  display: flex;
  flex-direction: column;
  border: solid 0.3vb transparent;
  border-radius: 2vb;
}

div.loginMenu > div.user > div.userMenu > a {
  display: block;
  color: #707070;
  text-align: center;
  padding: 1.4vb 1.6vb;
  text-decoration: none;
  font: 500 2vb "Segoe UI", sans-serif;
}

div.loginMenu > div.user > div.userMenu > a.active {
  display: block;
  color: #2b2b2b;
  text-align: center;
  padding: 1.4vb 1.6vb;
  text-decoration: none;
  font: 700 2vb "Segoe UI", sans-serif;
}