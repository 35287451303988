div.registerFormScene{
    position: absolute;
    top: -4vh;
    left: 1vw;
    width: 30vw;
    height: 58vh;
    background-color: transparent;
    z-index: 4;
}

div.registerFormScene_human1{
    position: absolute;
    top: 10vh;
    left: 1vw;
    width: 13vw;
    height: 44vh;
    background-color: transparent;
    z-index: 6;
}

div.registerFormScene_human2{
    position: absolute;
    top: 12vh;
    left: 9.5vw;
    width: 13vw;
    height: 42vh;
    background-color: transparent;
    z-index: 5;
}

div.registerFormContent{
    position:absolute;
    top: 0vh;
    left: 32vw;
    width: 28vw;
    height: 57vh;
    z-index: 4;
    opacity: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}