div.homeBackground {
  background: url('/public/img/home_background.png') no-repeat;
  background-position-x: -35px;
  background-position-y: -35px;
}

div.home {
  position: absolute;
  top: 8vh;
  width: 100vw;
  height: 92vh;
}

div.home > div.logo{
  position:absolute;
  top: 15vh;
  left: 5vw;
  width: 50vb;
  height: 43vb;
  opacity: 1;
}

div.home > div.logo > div.o2des{
  position:absolute;
  top: 0vh;
  left: 0vw;
  width: 68vb;
  height: 35vb;
  opacity: 1;
}

div.home > div.logo > div.studio{
  position:absolute;
  top: 24vb;
  left: 26vb;
  width: 52vb;
  height: 22vb;
  opacity: 1;
}

div.home > div.message{
  position:absolute;
  top: 55vh;
  left: 12vw;
  width: 60vb;
  height: 6vb;
}

div.home > div.message > p{
  color: #434343;
  opacity: 1;
  font: 400 3.5vb "Segoe UI", sans-serif;
}

div.home > div.start{
  position:absolute;
  top: 70vh;
  left: 13vw;
  width: 28vw;
  height: 12vh;
  background-color: white;
  border: solid white 0.1vb;
  border-radius: 6vh;
}

div.home > div.start input[type='button'] {
  border: solid 0.8vb transparent;
  border-radius: 5.5vh;
  background: 
      linear-gradient(to right, #ffffff, #ffffff ),
      linear-gradient(135deg, #eb98c0 0%, #49bde8 61.5%, #07a8a9 100%);
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  color: #424242;
  padding: 0.5vh 0.2vb;
  margin: 1vh;
  font: 600 5.5vb "Segoe UI", sans-serif;
  width: 27vw;
  height: 10vh;
  cursor: pointer;
}